
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { LoginPayload } from '@/store/interfaces';
  import AppVersion from '@/components/AppVersion.vue';

  const appStore = namespace('app')

  @Component({ components: { AppVersion } })
  export default class LoginPage extends Vue {
    username = '';
    password = '';

    get isFormFilled(): boolean {
      return Boolean(this.username && this.password);
    }

    @appStore.Action('login')
    loginUser!: (data: LoginPayload) => Promise<boolean>;

    async login(): Promise<void> {
      if (!this.isFormFilled) return;

      const { username, password } = this;
      const status = await this.loginUser({ username, password });
      status && this.$router.push({ name: 'home' }).catch(() => null);
    }
  }
